/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Avatar } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrivateLayout from '../../../layout/PrivateLayout';
import StyledJobsOverview from './StyledJobsOverview';
import savedJobsIcon from '../../../assets/svg/saved-jobs.svg';
import appliedJobsIcon from '../../../assets/svg/applied-jobs.svg';
import activeJobsIcon from '../../../assets/svg/active-jobs.svg';
import appliedbg from '../../../assets/svg/noappliedbg.svg';
import savedbg from '../../../assets/svg/nosavedbg.svg';
import suggestedbg from '../../../assets/svg/nosuggestedbg.svg';
import share from '../../../assets/svg/share-circle.svg';
import save from '../../../assets/svg/save-circle.svg';
import close from '../../../assets/svg/close-circle.svg';
import saved from '../../../assets/svg/savedBlue.svg';
import coin from '../../../assets/svg/coin-jobs.svg';
import locationPoint from '../../../assets/svg/location-jobs.svg';
import calendar from '../../../assets/svg/calendar-jobs.svg';
import { fetchApplyJob, fetchJobOverview, fetchSaveJob } from '../../../store/reducers/fractionTalent/apiThunk';
import { PrimaryButton } from '../../../components/Button';
import { selectorFractionTalent } from '../../../store/reducers/fractionTalent';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import { ROUTE_PATH } from '../../../constants/route';
import JobdetailsModal from './JobdetailsModal';
import JobShareModal from './JobShareModal';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { checkCompensationType, checkPlanType, durationCheck, stringAvatar } from '../../../helper';
import profilePic from '../../../assets/svg/profilePic.svg';

const { PREVIEW_TALENT, APPLY_JOB, SAVED_JOB, APPLIED_JOB } = ROUTE_PATH;

const JobsOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const navigateQuery = useNavigateQuery();
  // const navigate = useNavigate();
  const location = useLocation();
  const {
    userLandingData: {
      data: {
        expert: { firstName = '', lastName = '', profileImagePath = '', _id: expertId } = {},
        subscriptionDetails = {},
      } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  if (!subscriptionDetails && subscriptionDetails?.status !== 'created') {
    navigate('/fraction-talent/my-job-profile?activeStep=3');
  }

  const {
    fractionTalent: {
      data: {
        city = '',
        state = '',
        domain = '',
        experienceYears = '',
        skills = [],
        jobRole = '',
      } = {},
    } = {},
    jobOverView: {
      data: {
        suggestedJobs = [],
        appliedJobs = [],
        savedJobs = [],
        totalJobs = 0,
        totalAppliedJobs = 0,
        totalSavedJobs = 0,
      } = {},
    } = {},
  } = useSelector(selectorFractionTalent);

  const [jobDetails, setJobDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogShare, setOpenDialogShare] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [suggestedJobsLocal, setSuggestedJobsLocal] = useState(suggestedJobs);
  const [savedJobsLocal, setSavedJobsLocal] = useState(savedJobs);
  const [appliedJobsLocal, setAppliedJobsLocal] = useState(appliedJobs);
  const [shareId, setShareId] = useState(null);

  useEffect(() => {
    dispatch(fetchJobOverview());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSuggestedJobsLocal(suggestedJobs);
  }, [suggestedJobs]);

  useEffect(() => {
    setSavedJobsLocal(savedJobs);
  }, [savedJobs]);

  useEffect(() => {
    setAppliedJobsLocal(appliedJobs);
  }, [appliedJobs]);

  const handleNavigate = (url) => {
    navigate(url);
  };

  const handleJobDetails = (item) => {
    // const params = new URLSearchParams(location.search);
    // if (item.jobId) {
    //   setJobDetails(item.jobId);
    //   const { jobId: { _id: id } } = item;
    //   params.set('job-id', id);
    //   navigateQuery(`${location.pathname}?${params.toString()}`);
    // } else {
    //   const { _id: id } = item;
    //   params.set('job-id', id);
    //   setJobDetails(item);
    //   setJobId(id);
    // }
    // navigateQuery(`${location.pathname}?${params.toString()}`);
    // setOpenDialog(true);
    if (item.jobId) {
      const {
        jobId: { _id: id },
      } = item;
      navigate(`/job-detail?job-id=${id}`);
    } else {
      const { _id: id } = item;
      setJobId(id);
      navigate(`/job-detail?job-id=${id}`);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setJobDetails({});
  };

  const handleOpenShareDialog = (item) => {
    const params = new URLSearchParams(location.search);
    if (item.jobId) {
      const { jobId: { _id: id } } = item;
      params.set('job-id', id);
    } else {
      const { _id: id } = item;
      params.set('job-id', id);
    }
    const jobid = params.get('job-id');
    setShareId(jobid);
    setOpenDialogShare(true);
  };

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };

  const handleJobApply = async () => {
    const response = await dispatch(fetchApplyJob(jobId));
    const { payload: { status = false } = {} } = response;
    if (status) {
      ToastNotifySuccess('You have applied to the job succesfully!');
      dispatch(fetchJobOverview());
    }
  };
  const handleSaveJob = async (item, isAlreadySaved) => {
    let id;
    if (item.jobId) {
      const { jobId: { _id: jid = '' } } = item;
      id = jid;
    } else {
      const { _id: jid } = item;
      id = jid;
    }

    const data = {
      jobId: id,
      save: isAlreadySaved,
    };
    const response = await dispatch(fetchSaveJob(data));
    const { payload: { status = false } = {} } = response;
    if (status) {
      dispatch(fetchJobOverview());
      ToastNotifySuccess('Job status (saved/unsaved) updated successfully.');
    }
  };

  const removeSuggestedJobs = (itemToRemove) => {
    setSuggestedJobsLocal((prevItems) => prevItems.filter((_, index) => index !== itemToRemove));
  };

  const removeSavedJobs = (itemToRemove) => {
    setSavedJobsLocal((prevItems) => prevItems.filter((_, index) => index !== itemToRemove));
  };

  return (
    <StyledJobsOverview>
      <div className="page-wrap">
        <Grid container columnSpacing={3} className="overview-wrap">
          <Grid item md={8} lg={9} xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={4} className="job-statics-box">
                <div className="statics-card-wrap">
                  <a href="/fraction-talent/apply-job">
                    <div className="content">
                      <div>
                        <div className="active-stat">{totalJobs}</div>
                        <div className="title">Total active jobs</div>
                      </div>
                      <div><img src={activeJobsIcon} alt="" className="countTabsIcon" /></div>
                    </div>
                  </a>
                </div>
              </Grid>

              <Grid item xs={4} className="job-statics-box">
                <div className="statics-card-wrap">
                  <a href="/fraction-talent/track-job">
                    <div className="content">
                      <div>
                        <div className="active-stat">{totalAppliedJobs}</div>
                        <div className="title">Applied jobs</div>
                      </div>
                      <div><img src={appliedJobsIcon} alt="" className="countTabsIcon" /></div>
                    </div>
                  </a>
                </div>
              </Grid>

              <Grid item xs={4} className="job-statics-box">
                <div className="statics-card-wrap">
                  <a href="/fraction-talent/saved-job">
                    <div className="content">
                      <div>
                        <div className="active-stat">{totalSavedJobs}</div>
                        <div className="title">Saved jobs</div>
                      </div>
                      <div><img src={savedJobsIcon} alt="" className="countTabsIcon" /></div>
                    </div>
                  </a>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} my={2} className="mobile-profile-info">
              <Accordion sx={{ mt: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordion-heading"
                  >
                  My profile
                </AccordionSummary>
                <AccordionDetails>
                  <div className="profile-info-wrap">
                    <div><img src={`${profileImagePath ? `${process.env.REACT_APP_IMAGE_URL}/${profileImagePath}` : profilePic}`} alt="" className="profileImage" /></div>
                    <div className="name">{`${firstName} ${lastName}`}</div>
                    <div className="tech">{jobRole}</div>
                    <PrimaryButton onClick={() => navigate(`${PREVIEW_TALENT}?id=${expertId}`)}>
                      View Profile
                    </PrimaryButton>
                    <div className="divider" />
                    <div className="subheading"> Basic Details</div>
                    <div className="details-heading">Year(s) of Experience</div>
                    <div className="details-sub-heading">{experienceYears}</div>
                    <div className="details-heading">Current location</div>
                    <div className="details-sub-heading">{city}, {state}</div>
                    <div className="details-heading">Domain</div>
                    <div className="details-sub-heading">{domain}</div>
                    <div className="details-heading">Skills</div>
                    <div className="skill-chips-box">
                      {skills?.map((skill) => <span className="chips">{skill}</span>)}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mt: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordion-heading">
                  My profile
                </AccordionSummary>
                <AccordionDetails>
                  <div className="subscription-info-wrap">
                    <div className="heading">My Subscription</div>
                    <div className="sub-heading">You are currently subscribed to</div>
                    <div className="subscription-info-card">
                      <div className="top-chips-wrap">
                        <div className="plan-chip">
                          <div> {checkPlanType(subscriptionDetails?.planId?.item?.name)} Plan </div>
                        </div>
                        <div> {subscriptionDetails?.cancelInitiated ? <div className="plan-status canceled">Cancelled</div> : subscriptionDetails?.status !== 'active' && subscriptionDetails?.freeTrial ? <div className="plan-status active-plan">Free trial</div> : null}</div>
                      </div>
                      <div className="plan-duration">
                        {/* <span className="currency">₹ 1199</span><span className="time">For 6 months</span> */}
                        <span className="currency">{subscriptionDetails?.planId?.item?.name === 'Yearly' ? `₹ ${subscriptionDetails?.amount / 100}/Year` : subscriptionDetails?.planId?.item?.name === 'Monthly' ? `₹ ${subscriptionDetails?.amount / 100}/Month` : 'Free Trial'}</span>
                      </div>
                      <PrimaryButton onClick={() => navigate('/fraction-talent/subscription')}>View Details</PrimaryButton>
                    </div>
                    <div className="subscription-details-card">
                      <div className="heading">Subscription Start Date:</div>
                      <div className="sub-heading">{subscriptionDetails?.status === 'authenticated' ?
                        moment(subscriptionDetails?.createdAt).format('ll') :
                        moment(subscriptionDetails?.currentStart).format('ll') }
                      </div>
                    </div>
                    <div className="subscription-details-card">
                      <div className="heading">Subscription End Date:</div>
                      <div className="sub-heading">{subscriptionDetails?.status === 'authenticated' ?
                        moment(subscriptionDetails?.createdAt).format('ll') :
                        moment(subscriptionDetails?.currentStart).format('ll') }
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <div className="page-job-card-box">
              <div className="title">
                <div className="heading">Suggested Jobs</div>
                <div className="seeAll" onClick={() => handleNavigate(APPLY_JOB)}>
                  See All
                  <ChevronRightRoundedIcon sx={{ fontSize: '30px' }} />
                </div>
              </div>
              {suggestedJobsLocal.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: '20px' }}
                  className="job-card-list-wrap"
  >
                  {suggestedJobsLocal.slice(0, 2).map((item, idx) => (
                    <Grid item xs={12} md={12} lg={suggestedJobsLocal.length > 1 ? 6 : 12} key={idx}>
                      <div className="job-card-wrap">
                        <div className="card-top">
                          <div className="right">
                            {item?.companyLogo ?
                              <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${item?.companyLogo}`} alt="" />
                              : <Avatar {...stringAvatar(item?.companyName)} />}
                            <div>
                              <div className="job-title">{item?.jobRole}</div>
                              <div className="job-postedby">
                                {item?.companyName}
                              </div>
                            </div>
                          </div>
                          <div className="left">
                            <img className="brand-image" src={share} alt="" onClick={() => handleOpenShareDialog(item)} />
                            <img
                              className="brand-image"
                              src={item?.isSaved ? saved : save}
                              alt=""
                              onClick={() => (item?.isJobSaved ?
                                handleSaveJob(item, true)
                                : handleSaveJob(item, false))} />
                            <img className="brand-image" src={close} alt="" onClick={() => removeSuggestedJobs(idx)} />
                          </div>
                        </div>
                        <div className="skill-chips-box">
                          {item?.skills?.map((skill, index) => <span key={index} className="chips">{skill}</span>)}
                        </div>
                        <div className="card-bottom">
                          <div className="job-info-content">
                            <div className="icon-text-box-wrap">
                              <div className="icon-text-box">
                                <img alt="" src={locationPoint} />
                                <div className="sub-text">{item?.jobCity}, {item?.jobState}   <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                                  <span style={{ textTransform: 'capitalize' }}>{item?.locationType}</span>
                                </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={coin} />
                                <div className="sub-text">₹ {item?.compensationRate}/{`${checkCompensationType(item?.compensationType)}`} </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={calendar} />
                                <div className="sub-text">
                                  {item?.availabilityPeriod} {' '}
                                  {/* {durationCheck(
                                    item?.availableFor,
                                    item?.availability,
                                  )} */}
                                  {durationCheck(item?.availableFor, item?.availabilityPeriod)}
                                </div>
                              </div>
                            </div>
                            <div className="view-details" onClick={() => handleJobDetails(item)}>View Details</div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div className="job-card-box" style={{ backgroundImage: `url(${suggestedbg})` }}>
                  <div className="noSessionsHeading">No Suggested Jobs Yet!</div>
                </div>
              )}
            </div>
            <div className="page-job-card-box">
              <div className="title">
                <div className="heading">Applied Jobs</div>
                <div className="seeAll" onClick={() => handleNavigate(APPLIED_JOB)}>
                  See All
                  <ChevronRightRoundedIcon sx={{ fontSize: '30px' }} />
                </div>
              </div>
              {appliedJobsLocal.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: '20px' }}
                  className="job-card-list-wrap">
                  {appliedJobsLocal.slice(0, 2).map((item, idx) => (
                    <Grid item xs={12} md={12} lg={appliedJobsLocal.length > 1 ? 6 : 12} key={idx}>
                      <div className="job-card-wrap">
                        <div className="card-top">
                          <div className="right">
                            {item?.jobId?.companyLogo ?
                              <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${item?.jobId?.companyLogo}`} alt="" />
                              : <Avatar {...stringAvatar(item?.jobId?.companyName)} />}
                            <div>
                              <div className="job-title">{item?.jobId?.jobRole}</div>
                              <div className="job-postedby">
                                {item?.jobId?.companyName}
                              </div>
                            </div>
                          </div>
                          <div className="left">
                            <div className="applied-chip">Applied</div>
                          </div>
                        </div>
                        <div className="skill-chips-box">
                          {item?.jobId?.skills?.map((skill) => <span className="chips">{skill}</span>)}
                        </div>
                        <div className="card-bottom">
                          <div className="job-info-content">
                            <div className="icon-text-box-wrap">
                              <div className="icon-text-box">
                                <img alt="" src={locationPoint} />
                                <div className="sub-text">{item?.jobId?.jobCity}, {item?.jobId?.jobState}
                                  <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                                  <span style={{ textTransform: 'capitalize' }}>{item?.jobId?.locationType}</span>
                                </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={coin} />
                                <div className="sub-text">₹ {item?.jobId?.compensationRate}/{`${checkCompensationType(item?.jobId?.compensationType)}`} </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={calendar} />
                                <div className="sub-text">
                                  {item?.jobId?.availabilityPeriod} {' '}
                                  {durationCheck(item?.jobId?.availableFor, item?.jobId?.availabilityPeriod)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="job-info-content">
                            <div className="date-text">Applied on {dayjs(item?.jobId?.updatedAt).format('D MMM YYYY')}</div>
                            <div className="view-details" onClick={() => handleJobDetails(item)}>View Details</div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div className="job-card-box" style={{ backgroundImage: `url(${appliedbg})` }}>
                  <div className="noSessionsHeading">No Applied Jobs Yet!</div>
                </div>
              )}
            </div>
            <div className="page-job-card-box">
              <div className="title">
                <div className="heading">Saved Jobs</div>
                <div className="seeAll" onClick={() => handleNavigate(SAVED_JOB)}>
                  See All
                  <ChevronRightRoundedIcon sx={{ fontSize: '30px' }} />
                </div>
              </div>
              {savedJobsLocal.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: '20px' }}
                  className="job-card-list-wrap">
                  {savedJobsLocal.slice(0, 2).map((item, idx) => (
                    <Grid item xs={12} md={12} lg={savedJobsLocal.length > 1 ? 6 : 12} key={idx + 1}>
                      <div className="job-card-wrap">
                        <div className="card-top">
                          <div className="right">
                            {item?.jobId?.companyLogo ?
                              <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${item?.jobId?.companyLogo}`} alt="" />
                              : <Avatar {...stringAvatar(item?.jobId?.companyName)} />}
                            <div>
                              <div className="job-title">{item?.jobId?.jobRole}</div>
                              <div className="job-postedby">
                                {item?.jobId?.companyName}
                              </div>
                            </div>
                          </div>
                          <div className="left">
                            <img className="brand-image" src={share} alt="" onClick={() => handleOpenShareDialog(item)} />
                            <img
                              className="brand-image"
                              src={item?.jobId?.isJobSaved ? saved : save}
                              alt=""
                              onClick={() => (item?.isJobSaved ?
                                handleSaveJob(item, true)
                                : handleSaveJob(item, false))} />
                            <img className="brand-image" src={close} alt="" onClick={() => removeSavedJobs(idx)} />
                          </div>
                        </div>
                        <div className="skill-chips-box">
                          {item?.jobId?.skills.map((skill) => <span className="chips">{skill}</span>)}

                        </div>
                        <div className="card-bottom">
                          <div className="job-info-content">
                            <div className="icon-text-box-wrap">
                              <div className="icon-text-box">
                                <img alt="" src={locationPoint} />
                                <div className="sub-text">{item?.jobId?.jobCity}, {item?.jobId?.jobState}  <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                                  <span style={{ textTransform: 'capitalize' }}>{item?.jobId?.locationType}</span>
                                </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={coin} />
                                <div className="sub-text">₹ {item?.jobId?.compensationRate}/{`${checkCompensationType(item?.jobId?.compensationType)}`} </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={calendar} />
                                <div className="sub-text">
                                  {item?.jobId?.availabilityPeriod} {' '}
                                  {/* {durationCheck(item?.jobId?.availabilityType, item?.jobId?.availabilityPeriod)} */}
                                  {durationCheck(item?.jobId?.availableFor, item?.jobId?.availabilityPeriod)}
                                </div>
                              </div>
                            </div>
                            <div className="view-details" onClick={() => handleJobDetails(item)}>View Details</div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div
                  className="job-card-box"
                  style={{ backgroundImage: `url(${savedbg})` }}>
                  <div className="noSessionsHeading">No Saved Jobs Yet!</div>
                </div>
              )}
            </div>
          </Grid>

          <Grid item md={4} lg={3} xs={12} className="web-profile-info">
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <div className="profile-info-wrap">
                  <div className="heading">My profile</div>
                  <div><img src={`${profileImagePath ? `${process.env.REACT_APP_IMAGE_URL}/${profileImagePath}` : profilePic}`} alt="" className="profileImage" /></div>
                  <div className="name">{`${firstName} ${lastName}`}</div>
                  <div className="tech">{jobRole}</div>
                  <PrimaryButton onClick={() => navigate(`${PREVIEW_TALENT}?id=${expertId}`)}>
                    View Profile
                  </PrimaryButton>
                  <div className="divider" />
                  <div className="subheading"> Basic Details</div>
                  <div className="details-heading">Year(s) of Experience</div>
                  <div className="details-sub-heading">{experienceYears}</div>
                  <div className="details-heading">Current location</div>
                  <div className="details-sub-heading">{city}, {state}</div>
                  <div className="details-heading">Domain</div>
                  <div className="details-sub-heading">{domain}</div>
                  <div className="details-heading">Skills</div>
                  <div className="skill-chips-box">
                    {skills?.map((skill) => <span className="chips">{skill}</span>)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="subscription-info-wrap">
                  <div className="heading">My Subscription</div>
                  <div className="sub-heading">You are currently subscribed to</div>
                  <div className="subscription-info-card">
                    <div className="top-chips-wrap">
                      <div className="plan-chip">
                        <div> {checkPlanType(subscriptionDetails?.planId?.item?.name)} Plan</div>
                      </div>
                      <div> {subscriptionDetails?.cancelInitiated ? <div className="plan-status canceled">Cancelled</div> : subscriptionDetails?.status !== 'active' && subscriptionDetails?.freeTrial ? <div className="plan-status active-plan">Free trial</div> : null}</div>
                    </div>
                    <div className="plan-duration">
                      {/* <span className="currency">₹ 1199</span><span className="time">For 6 months</span> */}
                      <span className="currency"> {subscriptionDetails?.planId.item?.name === 'Yearly' ? `₹ ${subscriptionDetails?.amount / 100}/Year` : subscriptionDetails?.planId.item?.name === 'Monthly' ? `₹ ${subscriptionDetails?.amount / 100}/Month` : 'Free Trial'}</span>
                    </div>
                    <PrimaryButton onClick={() => navigate('/fraction-talent/subscription')}>View Details</PrimaryButton>
                  </div>
                  <div className="subscription-details-card">
                    <div className="heading">Subscription Start Date:</div>
                    <div className="sub-heading">{subscriptionDetails?.status === 'authenticated' ?
                      moment(subscriptionDetails?.createdAt).format('ll') :
                      moment(subscriptionDetails?.currentStart).format('ll') }
                    </div>
                  </div>
                  <div className="subscription-details-card">
                    <div className="heading">Subscription End Date:</div>
                    <div className="sub-heading">{subscriptionDetails?.status === 'authenticated' ?
                      moment(subscriptionDetails?.startAt).format('ll') :
                      moment(subscriptionDetails?.currentEnd).format('ll') }
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <JobdetailsModal
        openDialog={openDialog}
        closeDialog={handleCloseDialog}
        jobDetails={jobDetails}
        handleApplyJob={handleJobApply}
        handleSaveJob={() => handleSaveJob(jobDetails, !!(jobDetails.isJobSaved))}
        handleShare={() => handleOpenShareDialog(jobDetails)}
        />
      <JobShareModal
        openDialog={openDialogShare}
        closeDialog={handleCloseShareDialog}
        id={shareId}
        />
    </StyledJobsOverview>
  );
};

export default PrivateLayout(JobsOverview, false);
