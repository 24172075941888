/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import { checkPlanType } from '../../../helper';
import PrivateLayout from '../../../layout/PrivateLayout';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import { cancelSubscription } from '../../../store/reducers/fractionTalent/apiThunk';
import StyledSubscription from './StyledSubscription';
import Modal from '../../../components/Modal';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { fetchLandingPageData } from '../../../store/reducers/expertProfile/apiThunk';

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancelModal, setOpenCancelnModal] = useState(false);
  const { userLandingData: { data: { subscriptionDetails = {} } = {} } = {} }
  = useSelector(selectorExpertProfile);

  useEffect(() => { dispatch(fetchLandingPageData()); }, []);

  const cancelPlanHandler = async () => {
    const { subscriptionId } = subscriptionDetails;
    if (subscriptionId) {
      const response = await dispatch(cancelSubscription(subscriptionId));
      const { payload: { status = false } = {} } = response;
      if (status) {
        dispatch(fetchLandingPageData());
        ToastNotifySuccess('Subscription cancelled successfully');
        setOpenCancelnModal(false);
      }
    }
  };

  const handleCloseCancelSessionDialoag = () => {
    setOpenCancelnModal(false);
  };

  const cancelModalContent = () => (
    <>
      <div>Are you sure you want to cancel Subscription?</div>
      <div className="logout-button-wrap">
        <SecondaryButton border onClick={() => cancelPlanHandler()}>
          Yes
        </SecondaryButton>
        <PrimaryButton onClick={() => handleCloseCancelSessionDialoag()}>No</PrimaryButton>
      </div>
    </>
  );

  return (
    <div>
      <StyledSubscription>
        <div className="main-container">
          <div className="subscard-wrap">
            <div className="subscard-wrap-top">
              <div>
                <div className="heading">My Subscription Details</div>
                <div className="subtitle">
                  Manage your current package and explore additional features.
                </div>
              </div>
              <div className="cancel-btn-box">
                {subscriptionDetails && subscriptionDetails?.status !== 'created' ?
                  <SecondaryButton
                    disabled={subscriptionDetails?.cancelInitiated}
                    sx={{
                      width: '200px',
                      marginBottom: '16px',
                      border: '1px solid #ff3c3c',
                      color: '#DA3333',
                      '&:hover': {
                        color: '#DA3333',
                      },
                      '&:disabled': {
                        border: '1px solid #757575',
                      },
                    }}
                    onClick={() => setOpenCancelnModal(true)}
                    >
                    {subscriptionDetails?.status === 'authenticated' ? 'Cancel Trial' : 'Cancel Subscription'}
                  </SecondaryButton> : null}
              </div>
            </div>
            <div className="divider" />
            <div className="plan">
              <div className="plan-heading">Subscription plan</div>
              <div className="plan-sub-heading">You can only upgrade the subscription by cancelling the Reaccuring Current Subscription plan. Your current subscription won't be cancelled in this,
                only the future billings of current subscription will stop.
              </div>
              <div className="plan-cards">
                <div className="plan-card">
                  <div className="select-plan">
                    <div className="plan-duration">{checkPlanType(subscriptionDetails?.planId?.item?.name)} Plan</div>
                    {subscriptionDetails?.cancelInitiated ? <div className="plan-status canceled">Cancelled</div> : subscriptionDetails?.status !== 'active' && subscriptionDetails?.freeTrial ? <div className="plan-status active-plan">Free trial</div> : null}
                  </div>
                  <div className="plan-amount">
                    {subscriptionDetails?.planId?.item?.name === 'Yearly' ? `₹ ${subscriptionDetails?.amount / 100}/Year` : subscriptionDetails?.planId?.item?.name === 'Monthly' ? `₹ ${subscriptionDetails?.amount / 100}/Month` : 'Free Trial'}
                  </div>
                </div>
                {subscriptionDetails && subscriptionDetails?.status !== 'created' ?
                  <div className="plan-card-date">
                    <div>
                      <div className="plan-duration">Start Date</div>
                      <div className="plan-date">
                        {subscriptionDetails?.status === 'authenticated' ?
                          moment(subscriptionDetails?.createdAt).format('ll') :
                          moment(subscriptionDetails?.currentStart).format('ll') }
                      </div>
                    </div>
                    <div className="ver-divider" />
                    <div>
                      <div className="plan-duration">End Date</div>
                      <div className="plan-date">
                        {subscriptionDetails?.status === 'authenticated' ?
                          moment(subscriptionDetails?.startAt).format('ll') :
                          moment(subscriptionDetails?.currentEnd).format('ll') }
                      </div>
                    </div>
                  </div> : null}
              </div>
            </div>
            <div className="upgarde-box">
              <PrimaryButton
                disabled={subscriptionDetails?.planId?.period === 'yearly' || subscriptionDetails?.status === 'created'}
                sx={{ maxWidth: '170px' }}
                onClick={() => navigate('/fraction-talent/my-job-profile?activeStep=3')}>
                Upgrade Plan
              </PrimaryButton>
            </div>
          </div>
        </div>
      </StyledSubscription>
      <Modal
        openDialog={openCancelModal}
        closeDialog={handleCloseCancelSessionDialoag}
        dialogContent={cancelModalContent()}
        noHeader={false}
        heading="Cancel Subscription"
        dialogCss="logoutDialogWrap"
      />
    </div>
  );
};

export default PrivateLayout(Subscription, false);
