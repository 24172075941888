/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Checkbox,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Dialog,
  useMediaQuery } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import PrivateLayout from '../../../layout/PrivateLayout';
import StyledApplyJobs, { StyledFilterPopup } from './StyledApplyJobs';
import share from '../../../assets/svg/share-circle.svg';
import save from '../../../assets/svg/save-circle.svg';
import close from '../../../assets/svg/close-circle.svg';
import coin from '../../../assets/svg/coin-jobs.svg';
import locationPoint from '../../../assets/svg/location-jobs.svg';
import calendar from '../../../assets/svg/calendar-jobs.svg';
import filterButton from '../../../assets/svg/filter-mobile.svg';
import JobdetailsModal from '../jobsOverview/JobdetailsModal';
import JobShareModal from '../jobsOverview/JobShareModal';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import {
  fetchApplyJob,
  fetchSaveJob,
  fetchSearchingFilters,
  fetchSuggestedJobs,
} from '../../../store/reducers/fractionTalent/apiThunk';
import { selectorFractionTalent } from '../../../store/reducers/fractionTalent';
import { PrimaryButton } from '../../../components/Button';
import { StyledPaginationBox } from '../trackAppliedJob/StyledTracksJobs';
import { checkCompensationType, durationCheck, stringAvatar } from '../../../helper';
import TextFeild from '../../../components/TextField';
import saved from '../../../assets/svg/savedBlue.svg';
import StyledJobsOverview from '../jobsOverview/StyledJobsOverview';
import increase from '../../../assets/svg/increase.svg';
import decresae from '../../../assets/svg/decrease.svg';
import CheckboxIcon from '../../../components/icons/CheckboxIcon';
import CheckboxWeb from '../../../components/icons/CheckboxWeb';
import BigCrossIcon from '../../../components/icons/BigCrossIcon';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';

const rowsPerPage = 10;

function formatLabel(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
}
const availibility = [
  { name: 'Daily', value: 'daily' },
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
];

const ApplyJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [openDialogShare, setOpenDialogShare] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [checked, setChecked] = useState({
    domainList: [],
    industryList: [],
    jobRoleList: [],
    skillList: [],
    availability: [],
    jobPreference: [],
  });
  const [expandedKeys, setExpandedKeys] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [suggestedJobsLocal, setSuggestedJobsLocal] = useState(suggestedJobs);
  const [locationInput, setLocationInput] = useState('');
  const [locations, setLocations] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [experienceYears, setExperienceYears] = useState('');
  const [checkedAvailability, setCheckedAvailability] = useState([]);
  const [tucExperienceYears, setTucExperienceYears] = useState([]);
  const [errExperienceYears, setErrExperienceYears] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { searchingFilters: { data: { data: filtersData = {} } = {} } = {} } =
    useSelector(selectorFractionTalent) || {};

  const { userLandingData: { data: { subscriptionDetails = {} } = {} } = {} }
    = useSelector(selectorExpertProfile);

  if (!subscriptionDetails && subscriptionDetails?.status !== 'created') {
    navigate('/fraction-talent/my-job-profile?activeStep=3');
  }
  const isLgOrAbove = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    dispatch(fetchSearchingFilters());
  }, []);

  useEffect(() => {
    setSuggestedJobsLocal(suggestedJobs);
  }, [suggestedJobs]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage); // Adjust for zero-based index
  };

  const closeMobileFilterDialog = () => {
    setOpenMobileFilter(false);
  };

  const [activeKey, setActiveKey] = useState(Object.keys(filtersData)[0]);
  const [activeKeyFilter, setActiveKeyFilter] = useState(null);

  const getSuggestedJob = async () => {
    const filterLocations = [...checkedLocations, locationInput];
    const query = `search=${searchInput}&availabilities=${checkedAvailability}&skill=${checked?.skillList}&domains=${checked?.domainList}&industries=${checked?.industryList}&jobRoles=${checked?.jobRoleList}&location=${filterLocations}&jobTypes=${checked.jobPreference}&experiences=${experienceYears}&limit=${rowsPerPage}&page=${currentPage}`;
    const response = await dispatch(fetchSuggestedJobs(query));
    const {
      payload: { data: { data = [], count = 0, page = 0 } = {}, status = false },
    } = response;

    setPaginationData({
      count,
      page,
    });
    if (status) {
      setSuggestedJobs(data);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    getSuggestedJob();
  }, [rowsPerPage, currentPage, clearFilter, searchInput]);

  const handleJobDetails = (item) => {
    // const params = new URLSearchParams(location.search);
    // if (item.jobId) {
    //   setJobDetails(item.jobId);
    //   const {
    //     jobId: { _id: id },
    //   } = item;
    //   params.set('job-id', id);
    //   navigate(`${location.pathname}?${params.toString()}`);
    // } else {
    //   const { _id: id } = item;
    //   params.set('job-id', id);
    //   setJobDetails(item);
    //   setJobId(id);
    // }
    // navigate(`${location.pathname}?${params.toString()}`);
    // setOpenDialog(true);
    if (item.jobId) {
      const {
        jobId: { _id: id },
      } = item;
      navigate(`/job-detail?job-id=${id}`);
    } else {
      const { _id: id } = item;
      setJobId(id);
      navigate(`/job-detail?job-id=${id}`);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setJobDetails({});
  };
  const handleOpenShareDialog = (item) => {
    const params = new URLSearchParams(location.search);
    const { _id: id } = item;
    params.set('job-id', id);
    const jobid = params.get('job-id');
    setShareId(jobid);
    setOpenDialogShare(true);
  };

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };

  const handleJobApply = async () => {
    const response = await dispatch(fetchApplyJob(jobId));
    const { payload: { status = false } = {} } = response;
    if (status) {
      getSuggestedJob();
      ToastNotifySuccess('You have applied to the job succesfully!');
    }
  };
  const handleSaveJob = async (item, isAlreadySaved) => {
    const { _id: id } = item;
    const data = {
      jobId: id,
      save: isAlreadySaved,
    };
    const response = await dispatch(fetchSaveJob(data));
    const { payload: { status = false } = {} } = response;
    if (status) {
      getSuggestedJob();

      ToastNotifySuccess('Job status (saved/unsaved) updated successfully.');
    }
  };

  // Initialize checked state with empty arrays for each filter category
  useEffect(() => {
    const initialCheckedState = Object.keys(filtersData).reduce((acc, key) => {
      acc[key] = []; // Start with empty arrays for each category
      return acc;
    }, {});
    setChecked(initialCheckedState);
  }, [filtersData]);

  const handleToggle = (filterKey, itemName) => {
    setChecked((prevChecked) => {
      // Ensure that the current filter (filterKey) exists and is an array
      const currentChecked = prevChecked[filterKey] || [];

      // Check if the item is already checked
      const isChecked = currentChecked.includes(itemName);

      // Update the checked state based on whether the item was checked or unchecked
      const updatedChecked = isChecked
        ? currentChecked.filter((item) => item !== itemName) // Remove item
        : [...currentChecked, itemName]; // Add item

      return {
        ...prevChecked, // Spread previous state to preserve other filters
        [filterKey]: updatedChecked, // Only update the specific filter key
      };
    });
  };

  // Handle expand/collapse toggle
  const handleExpand = (key) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle expanded state
    }));
  };

  const handleToggleAvailability = (value) => {
    const currentIndex = checkedAvailability.indexOf(value);
    const newChecked = [...checkedAvailability];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAvailability(newChecked);
  };

  const handleClear = () => {
    setChecked({
      domainList: [],
      industryList: [],
      jobRoleList: [],
      skillList: [],
      jobPreference: [],
    });
    setCheckedLocations([]);
    setLocations([]);
    setLocationInput('');
    closeMobileFilterDialog();
    setExperienceYears('');
    setCheckedAvailability([]);
    setClearFilter(!clearFilter);
  };

  const removeSuggestedJobs = (itemToRemove) => {
    setSuggestedJobsLocal((prevItems) => prevItems.filter((_, index) => index !== itemToRemove));
  };

  const handleAddLocation = () => {
    if (locationInput.trim()) {
      const newLocation = locationInput.trim();
      setLocations((prevLocations) => [...prevLocations, newLocation]);
      setCheckedLocations((prevChecked) => [...prevChecked, newLocation]);
      setLocationInput('');
    }
  };

  const handleDecrease = () => {
    setTucExperienceYears(true);
    const currentExperience = parseInt(experienceYears) || 0;
    setExperienceYears(currentExperience - 1);
    if ((currentExperience - 1) < 0) {
      setErrExperienceYears('Experience years cannot be negative');
    } else if ((currentExperience - 1) > 50) {
      setErrExperienceYears('Experience years cannot exceed 50');
    } else {
      setErrExperienceYears('');
    }
  };

  const handleIncrease = () => {
    setTucExperienceYears(true);
    const currentExperience = parseInt(experienceYears) || 0;
    setExperienceYears(currentExperience + 1);
    if ((currentExperience + 1) > 50) {
      setErrExperienceYears('Experience years cannot exceed 50');
    } else {
      setErrExperienceYears('');
    }
  };

  const RenderExp = () => (
    <div className="exp-buttons">
      <img alt="" src={decresae} onClick={handleDecrease} />
      <img alt="" src={increase} onClick={handleIncrease} />
    </div>
  );

  const customHandleChange = (e) => {
    setTucExperienceYears(true);
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      setExperienceYears(changedValue);
      if (changedValue > 50) {
        setErrExperienceYears('Experience years cannot exceed 50');
      } else {
        setErrExperienceYears('');
      }
    } else {
      const changedValue = e.target.value;
      setExperienceYears(changedValue);
      if (changedValue > 50) {
        setErrExperienceYears('Experience years cannot exceed 50');
      } else {
        setErrExperienceYears('');
      }
    }
  };

  const filteredList = filtersData[activeKeyFilter]?.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

  useEffect(() => { setSearchQuery(''); }, [activeKeyFilter]);

  return (
    <>
      <StyledApplyJobs>
        <div className="page-wrap">
          <div className="filter-search">
            <TextFeild
              fullWidth
              size="small"
              placeholderValue="Search by Name, Email, Job Role, Industry, Domain"
              sx={{
                marginBottom: '10px !importatnt',
                minHeight: '0px !important',
                background: 'white !important',
              }}
              InputProps={{
                sx: {
                  backgroundColor: 'white !important',
                },
                disableUnderline: true,
              }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <Grid container columnSpacing={3} className="suggested-content-wrap">
            <Grid item md={4} lg={3} xs={0} className="web-filters">
              <div className="filters-wrap">
                <div className="section-title">Filter</div>
                <div className="section-content">
                  {Object?.keys(filtersData)?.map((key) => (
                    <div key={key}>
                      <div className="section-heading">
                        {key ? formatLabel(key.replace('List', '')) : null}
                      </div>
                      {filtersData[key]
                        .slice(0, expandedKeys[key] ? filtersData[key].length : 4)
                        .map((item) => (
                          <ListItemButton
                            key={item._id}
                            role="listitem"
                            onClick={() => handleToggle(key, item.name)}>
                            <ListItemIcon>
                              <Checkbox
                                checked={checked[key]?.includes(item.name) || false}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': item._id,
                                }}
                                icon={isLgOrAbove ? <CheckboxWeb /> : <CheckboxIcon />}
                                />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ textTransform: 'capitalize' }}
                              id={item._id}
                              primary={` ${item.name === 'onSite' ? 'On-site' : item.name}`}
                            />
                          </ListItemButton>
                        ))}
                      {filtersData[key].length > 4 && !expandedKeys[key] && (
                      <span
                        className="more-text"
                        onClick={() => {
                          handleExpand();
                          setActiveKeyFilter(key);
                        }}
                      >
                        {`+${filtersData[key].length - 4} more`}
                      </span>
                      )}
                      {activeKeyFilter === key && (
                      <div className="show-more-box">
                        <div className="show-more-content">
                          <div className="show-more-header">
                            <TextFeild
                              placeholderValue="Search"
                              className="show-more-search"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div
                              onClick={() => { setActiveKeyFilter(null); }}
                              style={{ cursor: 'pointer' }}
                            >
                              <BigCrossIcon width="35" height="35" />
                            </div>
                          </div>
                          <div className="show-more-checkbox">
                            {filteredList?.length === 0 && searchQuery.length > 0 ? (
                              <div>No results found for "{searchQuery}"</div>
                            ) : (
                              filteredList?.map((item) => (
                                <ListItemButton
                                  key={item._id}
                                  role="listitem"
                                  onClick={() => handleToggle(key, item.name)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={checked[key]?.includes(item.name)}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        'aria-labelledby': item.name,
                                      }}
                                      icon={isLgOrAbove ? <CheckboxWeb /> : <CheckboxIcon />}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={item.name} />
                                </ListItemButton>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                      )}
                    </div>
                  ))}

                  <div className="section-heading">Availability</div>
                  {availibility?.map((item, index) => (
                    <ListItemButton
                      key={index}
                      role="listitem"
                      onClick={() => handleToggleAvailability(item.value)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedAvailability.includes(item.value)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': item.name,
                          }}
                          icon={isLgOrAbove ? <CheckboxWeb /> : <CheckboxIcon />}
                        />
                      </ListItemIcon>
                      <ListItemText primary={` ${item?.name}`} />
                    </ListItemButton>
                  ))}

                  <div className="section-heading">Experience</div>
                  <TextFeild
                    name="experienceYears"
                    type="number"
                    label="Year(s) Of Experience"
                    classes="exper"
                    showTooltip={false}
                    placeholderValue="Years of experience"
                    onChange={(e) => customHandleChange(e)}
                    value={experienceYears}
                    error={tucExperienceYears && !!errExperienceYears}
                    helperText={tucExperienceYears && errExperienceYears}
                    icon={<RenderExp />}
                    />

                  <div className="section-heading">Location</div>
                  <TextFeild
                    value={locationInput}
                    onChange={(e) => setLocationInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleAddLocation()}
                    fullWidth
                    size="small"
                    placeholderValue="Search"
                    sx={{ marginBottom: '10px !importatnt', minHeight: '0px !important' }}
                  />
                  {locations.map((locItem, index) => (
                    <ListItemButton
                      key={index}
                      role="listitem"
                      onClick={() => handleToggle('location', locItem)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedLocations.includes(locItem)}
                          tabIndex={-1}
                          disableRipple

                        />
                      </ListItemIcon>
                      <ListItemText primary={locItem} />
                      <span onClick={() => locations.splice(index, 1)} style={{ padding: '0px 8px' }}>
                        <CloseIcon fontSize="16px" />
                      </span>
                    </ListItemButton>
                  ))}

                </div>

                <div className="filter-buttons">
                  <span className="clear-btn" onClick={handleClear}>
                    Clear
                  </span>
                  <PrimaryButton disabled={experienceYears > 50} sx={{ width: '106px' }} onClick={getSuggestedJob}>
                    Apply Filter
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
            <Grid item md={8} lg={9} xs={12}>
              <div className="suggested-jobs-wrap">
                <div className="section-title">Suggested Jobs</div>
                <Grid container spacing={2} sx={{ padding: '20px' }} className="job-card-list-wrap">
                  {suggestedJobsLocal.map((item, idx) => (
                    <Grid item xs={12} md={12} lg={suggestedJobsLocal.length > 1 ? 6 : 12} key={idx}>
                      <div className="job-card-wrap">
                        <div className="card-top">
                          <div className="right">
                            {item.companyLogo ? (
                              <img
                                className="brand-image"
                                src={`${process.env.REACT_APP_IMAGE_URL}/${item.companyLogo}`}
                                alt=""
                              />
                            ) : (
                              <Avatar {...stringAvatar(item?.companyName)} />
                            )}
                            <div>
                              <div className="job-title">{item.jobRole}</div>
                              <div className="job-postedby">{item?.companyName}</div>
                            </div>
                          </div>
                          <div className="left">
                            <img
                              className="brand-image"
                              src={share}
                              alt=""
                              onClick={() => handleOpenShareDialog(item)}
                            />
                            <img
                              className="brand-image"
                              src={item.isSaved ? saved : save}
                              alt=""
                              onClick={() => (jobDetails?.isJobSaved ?
                                handleSaveJob(item, true)
                                : handleSaveJob(item, false))}
                            />
                            <img
                              className="brand-image"
                              src={close}
                              alt=""
                              onClick={() => removeSuggestedJobs(idx)}
                            />
                          </div>
                        </div>
                        <div className="skill-chips-box">
                          {item?.skills?.map((skill) => (
                            <span className="chips">{skill}</span>
                          ))}
                        </div>
                        <div className="card-bottom">
                          <div className="job-info-content">
                            <div className="icon-text-box-wrap">
                              <div className="icon-text-box">
                                <img alt="" src={locationPoint} />
                                <div className="sub-text">
                                  {item?.jobCity}, {item?.jobState}{' '}
                                  <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                                  <span style={{ textTransform: 'capitalize' }}>
                                    {item?.locationType}
                                  </span>
                                </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={coin} />
                                <div className="sub-text">
                                  ₹ {item?.compensationRate}/
                                  {`${checkCompensationType(item?.compensationType)}`}{' '}
                                </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={calendar} />
                                <div className="sub-text">
                                  {item.availabilityPeriod}{' '}
                                  {/* {durationCheck(
                                    item?.jobId?.availableFor,
                                    item?.jobId?.availability,
                                  )} */}
                                  {durationCheck(item?.availableFor, item?.availabilityPeriod)}
                                </div>
                              </div>
                            </div>
                            <div className="view-details" onClick={() => handleJobDetails(item)}>
                              View Details
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid className="mobile-filter-btn">
            <IconButton color="primary" aria-label="add to shopping cart" size="medium" onClick={() => setOpenMobileFilter(true)}>
              <img alt="" src={filterButton} width="60px" height="60px" />
            </IconButton>
          </Grid>
        </div>
      </StyledApplyJobs>

      {suggestedJobs.length !== 0 ? (
        <StyledPaginationBox>
          <div className="jobs-pagination-box">
            <div className="left-wrap">
              <TablePagination
                component="div"
                count={paginationData?.count}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </div>
            <div className="right-wrap">
              <Pagination
                variant="outlined"
                shape="rounded"
                size="medium"
                page={currentPage}
                count={Math.ceil(paginationData?.count / rowsPerPage)}
                onChange={(event, newPage) => {
                  handleChangePage(event, newPage);
                }}
              />
            </div>
          </div>
        </StyledPaginationBox>
      ) : (
        ''
      )}
      <StyledJobsOverview>
        <JobdetailsModal
          openDialog={openDialog}
          closeDialog={handleCloseDialog}
          jobDetails={jobDetails}
          handleApplyJob={handleJobApply}
          handleSaveJob={() => handleSaveJob(jobDetails)}
          handleShare={() => handleOpenShareDialog(jobDetails)}
        />
        <JobShareModal
          openDialog={openDialogShare}
          closeDialog={handleCloseShareDialog}
          id={shareId}
        />
        <Dialog
          fullScreen
          open={openMobileFilter}
          onClose={closeMobileFilterDialog}>
          <StyledFilterPopup>
            <div className="mobile-filter-popup-content-wrap">

              <div className="filter-headers">
                <div>Filters</div>
                <div className="close-btn" onClick={closeMobileFilterDialog}>Close</div>
              </div>

              <div className="filter-content-wrap">
                <Grid container rowSpacing={1} columnSpacing={1}>
                  {/* Left section: List of keys */}
                  <Grid item xs={4}>
                    <div className="content-box">
                      {Object.keys(filtersData).map((key) => (
                        <div
                          key={key}
                          className={`section-heading ${activeKey === key ? 'active' : ''}`} // Add an "active" class for styling
                          onClick={() => setActiveKey(key)} // Set the clicked key as active
                          style={{
                            cursor: 'pointer',
                            color: activeKey === key ? '#415DF3' : '#050505',
                          }}>
                          {key ? formatLabel(key.replace('List', '')) : null}
                        </div>
                      ))}
                      <div
                        onClick={() => setActiveKey('location')}
                        className={`section-heading ${activeKey === 'location' ? 'active' : ''}`}
                        style={{
                          cursor: 'pointer',
                          color: activeKey === 'location' ? '#415DF3' : '#050505',
                        }}>
                        Location
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="content-box">
                      <div className="filter-suggestions-heading">
                        FILTER BY {activeKey ? formatLabel(activeKey.replace('List', '')) : null }
                      </div>
                      {filtersData[activeKey]?.map((item) => (
                        <ListItemButton
                          key={item._id}
                          role="listitem"
                          onClick={() => handleToggle(activeKey, item.name)}>
                          <ListItemIcon>
                            <Checkbox
                              checked={checked[activeKey]?.includes(item.name) || false}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                'aria-labelledby': item._id,
                              }}
                              icon={<CheckboxIcon />}
                              />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ textTransform: 'capitalize' }}
                            id={item._id}
                            primary={item.name} />
                        </ListItemButton>
                      ))}
                      {activeKey === 'location' ?
                        <Grid p={2} pt={0}>
                          <TextFeild
                            value={locationInput}
                            onChange={(e) => setLocationInput(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && handleAddLocation()}
                            fullWidth
                            size="small"
                            placeholderValue="Search"
                            sx={{ marginBottom: '10px !importatnt', minHeight: '0px !important' }} />
                          {locations.map((locItem, index) => (
                            <ListItemButton
                              key={index}
                              role="listitem"
                              onClick={() => handleToggle('location', locItem)}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={checkedLocations.includes(locItem)}
                                  tabIndex={-1}
                                  disableRipple
                        />
                              </ListItemIcon>
                              <ListItemText primary={locItem} />
                            </ListItemButton>
                          ))}
                        </Grid> : null}
                    </div>

                  </Grid>
                </Grid>
              </div>
              <div className="action-buttons">
                <div className="filter-buttons">
                  <span className="clear-btn" onClick={handleClear}>
                    Clear
                  </span>
                  <PrimaryButton sx={{ width: '106px' }} onClick={getSuggestedJob}>
                    Apply Filter
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </StyledFilterPopup>
        </Dialog>
      </StyledJobsOverview>
    </>
  );
};

export default PrivateLayout(ApplyJobs, false);
