/* eslint-disable max-len */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRazorpay from 'react-razorpay';
import { Box } from '@mui/system';
import checkedIcon from '../../../assets/svg/checked-blue.svg';
import lockIcon from '../../../assets/svg/lock.svg';
import StyledSubscription from './StyledSubscription';
import { PrimaryButton } from '../../../components/Button';
import { cancelSubscription, cretaeSubscription, fetchSubscriptionPlans } from '../../../store/reducers/fractionTalent/apiThunk';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import {
  fetchLandingPageData,
} from '../../../store/reducers/expertProfile/apiThunk';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import Modal from '../../../components/Modal';
import confirmed from '../../../assets/svg/confirmed.svg';
import logo from '../../../assets/images/logo512.png';

function Subscription() {
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const [active, setActive] = useState(0);
  const [planList, setPlanList] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { userLandingData: { data: { subscriptionDetails = {}, expert: { freeTrial = false } = {} } = {} } = {} } = useSelector(selectorExpertProfile);

  const getSubscriptionPlans = async () => {
    const response = await dispatch(fetchSubscriptionPlans()).then((val) => val);
    const { payload: { data = [] } = {} } = response;
    setPlanList(data?.expertPlans);
  };

  useEffect(() => {
    getSubscriptionPlans();
    dispatch(fetchLandingPageData());
  }, []);

  const razorPayHandler = async (subscriptionId) => {
    if (subscriptionId) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: 'INR',
        name: 'SuperConnects',
        subscription_id: subscriptionId,
        image: logo, // Use the imported logo

        handler(response) {
          if (response.razorpay_payment_id) {
            setOpenConfirmModal(true);
            dispatch(fetchLandingPageData());
          }
        },
        theme: {
          color: '#415DF3',
        },
        modal: {
          ondismiss() {
            dispatch(cancelSubscription(subscriptionId));
          },
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on('payment.failed', (response) => {
        if (response.error.reason === 'payment_failed') {
          dispatch(cancelSubscription(subscriptionId));
          ToastNotifySuccess('Payment_failed');
        }
      });

      rzp1.open();
    }
  };

  const selectPlanHandler = async () => {
    if (planId) {
      const postData = { planId };
      const response = await dispatch(cretaeSubscription(postData)).then((val) => val);
      const { payload: { data: { subscriptionId = '' } = {}, status = false } = {} } = response;
      if (status) {
        if (subscriptionId) {
          await razorPayHandler(subscriptionId);
          setPlanId(null);
        }
      }
    }
  };

  // Automatically set the active plan based on subscriptionDetails
  useEffect(() => {
    if (subscriptionDetails?.planId?.id && planList?.length > 0) {
      const index = planList.findIndex((plan) => plan.id === subscriptionDetails?.planId?.id);
      if (index !== -1) {
        setActive(index);
      }
    }
  }, [subscriptionDetails, planList]);

  const cardClickHandler = (plan, index) => {
    if (index !== 0) {
      setPlanId(plan?.item?.id);
    }
    setActive(index);
  };

  const plans = [
    {
      plan: 'Starter Plan',
      Amount: 'Free',
      planDuration: '',
      item: {},
      detailsList: [
        { item: 'Set up Profile', icon: checkedIcon },
        { item: 'Monetise the Services', icon: checkedIcon },
        { item: 'Analytics on Profile Visits', icon: checkedIcon },
        { item: 'Transaction Fee on Calls', icon: '', percent: 20 },
        { item: 'Access to Job Listing ', icon: lockIcon },
        { item: 'Curated Job Listing from Other Networks', icon: lockIcon },
        { item: 'Connect With Other Fractional Experts', icon: lockIcon },
        { item: 'Access to In-person Events', icon: lockIcon },
        { item: 'Access to SAAS Market Place for Reselling', icon: lockIcon },
        { item: 'Content Support', icon: lockIcon },
        { item: 'Access to Preferred Partners : WebsiteDesign / Digital Marketers', icon: lockIcon },
      ],
    },
    {
      plan: 'Monthly  Plan',
      Amount: 2500,
      planDuration: '/Month',
      offer: '🎉 First Month Free',
      item: planList[1],
      detailsList: [
        { item: 'Set up Profile', icon: checkedIcon },
        { item: 'Monetise the Services', icon: checkedIcon },
        { item: 'Analytics on Profile Visits', icon: checkedIcon },
        { item: 'Transaction Fee on Calls', icon: '', percent: 10 },
        { item: 'Access to Job Listing ', icon: checkedIcon },
        { item: 'Curated Job Listing from Other Networks', icon: checkedIcon },
        { item: 'Connect With Other Fractional Experts', icon: lockIcon },
        { item: 'Access to In-person Events', icon: lockIcon },
        { item: 'Access to SAAS Market Place for Reselling', icon: lockIcon },
        { item: 'Content Support', icon: lockIcon },
        { item: 'Access to Preferred Partners : WebsiteDesign / Digital Marketers', icon: lockIcon },
      ],
    },
    {
      plan: 'Annual  Plan',
      Amount: 30000,
      planDuration: '/Year',
      offer: '🎉 First Month Free',
      item: planList[0],
      detailsList: [
        { item: 'Set up Profile', icon: checkedIcon },
        { item: 'Monetise the Services', icon: checkedIcon },
        { item: 'Analytics on Profile Visits', icon: checkedIcon },
        { item: 'Transaction Fee on Calls', icon: '', percent: '0' },
        { item: 'Access to Job Listing ', icon: checkedIcon },
        { item: 'Curated Job Listing from Other Networks', icon: checkedIcon },
        { item: 'Connect With Other Fractional Experts', icon: checkedIcon },
        { item: 'Access to In-person Events', icon: checkedIcon },
        { item: 'Access to SAAS Market Place for Reselling', icon: checkedIcon },
        { item: 'Content Support', icon: checkedIcon },
        { item: 'Access to Preferred Partners : WebsiteDesign / Digital Marketers', icon: checkedIcon },
      ],
    },
  ];

  const PlanDetailItem = ({ item, icon, percent }) => (
    <div className="plan-details-list">
      <div className="left">{item}</div>
      <div className={`${percent > 0 ? 'right' : 'right blue'} `}>{percent ? `${percent}%` : <img src={icon} alt="checkmark" />}</div>
    </div>
  );

  const handleClosePostConfirmDialoag = () => {
    setOpenConfirmModal(false);
  };

  const confirmModalPostContent = () => (
    <div className="feedback-confirm-modal-content">
      <div className="heading-text">Congratulations! You've successfully purchased your subscription. It may take 2-3 minutes to update in your profile.</div>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <PrimaryButton
          sx={{ width: '154px' }}
          onClick={() => handleClosePostConfirmDialoag()}>
          Continue
        </PrimaryButton>
      </Box>
    </div>
  );

  return (
    <StyledSubscription>
      <div className="main-container">
        <div className="subscard-wrap">
          <div className="subscard-wrap-top">
            <div className="heading">Get 10X return on your investment...</div>
            <div className="sub-title">You are a one person company and this is your marketing  investment. </div>
          </div>
          <div className="plan">
            <div className="plan-cards">
              {plans?.map((plan, index) => (
                <div
                  className={`plan-card ${active === index ? 'active' : ''}`}
                  onClick={() => cardClickHandler(plan, index)}
                  onMouseEnter={() => cardClickHandler(plan, index)}
                  >
                  <div className={(plan?.offer && freeTrial) ? 'offer-strip' : 'offer-strip-box'} style={{ backgroundColor: active === index ? '#ffffff' : '' }}>{freeTrial ? plan?.offer : ''}</div>
                  <div className="select-plan">
                    <div className="plan-duration">{plan?.plan} {subscriptionDetails?.planId?.id ? <div>{subscriptionDetails?.planId?.id === plan?.item?.id ? <div className="current-plan">Current Plan</div> : subscriptionDetails?.amount < plan?.item?.item?.amount ? <div className="upgrade-plan">upgrade to</div> : ''}</div> : index === 0 ? <div className="current-plan">Current Plan</div> : <div className="upgrade-plan">upgrade to</div>}</div>
                  </div>
                  <div className="plan-amount">
                    ₹ {plan?.item?.item?.amount / 100 || 'Free'}<span>{plan?.planDuration}</span>
                  </div>
                  <div className="select-plan">
                    {plan?.item?.item ?
                      <PrimaryButton
                        disabled={subscriptionDetails?.status === 'created' || (subscriptionDetails?.planId && subscriptionDetails?.planId?.id === plan?.item?.id)}
                        onClick={() => {
                          selectPlanHandler();
                        }}>
                        Select Plan
                      </PrimaryButton>
                      : null}
                  </div>
                  <div className="plan-details">
                    <div className="title">Subscription Plan</div>
                    {plan?.detailsList.map((i) => <PlanDetailItem key={i.item} item={i.item} icon={i.icon} percent={i.percent} />)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="faq-wrap">
          <div className="title">FAQs</div>
          <div className="accordion-wrap">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#0D141C' }} />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography component="span">Investment in a year  for a monthly subscription is 12 x 2500  =  30,000.
                  One Additional Fractional Role  generates more than 10 X revenue.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Two additional Expert Calls will help you Break Even your cost.I sincerely believe if
                you area subject matter expert both scenarios 1 & 2
                are possible and your ROI will be at least 20 times........
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <Typography component="span">How will I get you more expert calls and fractional roles ?</Typography>
              </AccordionSummary>
              <AccordionDetails>I will reach out to relevant VCs, founders,  Head of Function at mutiple companies and develop awareness of Fractional Professionals with Subject Matter Expertise on this Platform.
                My singular focus is to build awareness of You and this Platform in front of relevant audience.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel2-header"
            >
                <Typography component="span">What else do you get?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Noise Free Platform :)
                Unlike other social networks or job platforms your candidature is not lost in hundrerds of applicantions for each role.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
            >
                <Typography component="span">Exclusivity : because most people do not pay for this kind of service :) </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Exactly and we dont want such people on this network. My goal is to build a very tight,
                highly useful network of Fractional Professionals across mutiple functions likes Sales, Marketing, Finance etc.
                A network that will help each other as well in mutliple ways.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
            >
                <Typography component="span">High Qualtiy Network </Typography>
              </AccordionSummary>
              <AccordionDetails>
                The North star for me is Quality of Professionals and Jobs on this network.
                I will limit the total number of professional on this Platform to ensure I protect quality of talent all the time.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
            >
                <Typography component="span">Farming : New leads </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Besides direct job listings on our platform, you will also get access to curated Job listing from Other Networks via email.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
            >
                <Typography component="span">Content Creation</Typography>
              </AccordionSummary>
              <AccordionDetails>
                I will collect and curate the content from various sources that will help you in your content creation strategy.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
            >
                <Typography component="span">Access to SAAS Marketplace </Typography>
              </AccordionSummary>
              <AccordionDetails>
                A list of SAAS tools that are available for you to deploy to various clients and earn referral revenue.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <Modal
        openDialog={openConfirmModal}
        closeDialog={handleClosePostConfirmDialoag}
        dialogContent={confirmModalPostContent()}
        noHeader={false}
        heading={<img src={confirmed} alt="confirmed" />}
        dialogCss="confirmFeedbackWrap"
        closeButVisible
      />
    </StyledSubscription>

  );
}

export default Subscription;
