import { styled } from '@mui/material/styles';
import subscriptionBG from '../../../assets/svg/subdetailBG.svg';

const StyledSubscription = styled('div')(
  ({ theme }) => `
    .main-container {
    margin:32px;
    @media screen and (max-width: 900px){
      margin:16px;
    }
      .subscard-wrap {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background:#ffffff;
        border-radius: 24px;
        .subscard-wrap-top{
          width:100%;
          padding:20px 24px 0px 20px;
          display:flex;
          justify-content: space-between;
          flex-wrap:wrap;
          background: url(${subscriptionBG});
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          .heading {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Sora";
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
          }
          .subtitle {
            padding-bottom: 20px;
            color: ${theme.palette.customColor.secondaryGrey};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-weight: 400;
            text-align:left;
          }
          .cancel-btn-box{
            @media screen and (max-width: 900px){
              width: 100%;
              text-align:center;
            }
          }
        }
        .divider{
          width: 100%;
          height:1px;
          background:#E2E4E7;
          margin-bottom:24px;
        }

        .plan {
          padding:0px 24px 24px;
          width:100%;
          .plan-heading {
            color: ${theme.palette.customColor.primaryBlack};
            font-family:Plus Jakarta Sans;
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            line-height:25px;
            color:#1C1C1C;
          }
          .plan-sub-heading{
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color:#525252;

          }
          .plan-cards {
            margin-top: 16px; 
            display: flex;
            align-items: end;
            gap:18px;      
            flex-wrap: wrap;
            .plan-card {
              position:relative;
              background:#ffffff;
              min-width: 320px;
              border: 1px solid ${theme.palette.customColor.grey_6};
              border-radius: 12px;
              padding:16px;
              @media screen and (max-width: 786px){
                min-width:100%;
              }
              .select-plan{
                .plan-status{
                  position:absolute;
                  right:16px;
                  top:16px;
                  font-family: Plus Jakarta Sans;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 15.12px;
                  text-align: left;
                  text-transform:uppercase;
                }
                .canceled {
                  color:#DA3333;
                }
                .active-plan {
                  color:#00AF31;
                  background:#00AF311A;
                  border:1px solid #00AF31;
                  padding:3.5px 8px;
                  border-radius:30px;
                  font-size: 10px;
                  font-weight: 700;
                  line-height: 12px;
                }
              }
              .plan-duration {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Plus Jakarta Sans;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                text-align: left;
                margin-bottom:14px;
              }
              .plan-amount {
                text-align: left;
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Sora;
                font-size: 24px;
                font-weight: 700;
                line-height: 30.24px;
                text-align: left;
              }
            }
            .plan-card-date{
              background:#ffffff;
              min-width: 200px;
              border: 1px solid ${theme.palette.customColor.grey_6};
              border-radius: 12px;
              padding:11px 16px;
              display:flex;
              flex-wrap:wrap;
              .plan-duration {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Plus Jakarta Sans;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                text-align: left;
                margin-bottom:14px;
              }
              .ver-divider{
                background:#E2E4E7;
                width:1px;
                margin:0 16px;
                height:75px;
                @media screen and (max-width: 786px){
                  width:100%;
                  margin:16px 0px;
                  height:1px;
                }
                
              }
              .plan-date{
                font-family: Sora;
                font-size: 20px;
                font-weight: 600;
                line-height: 25.2px;
                text-align: left;
                margin-right:54px;
                @media screen and (max-width: 786px){
                  margin-right:0px;
                }
                
              }
            }
          }
        }
        .upgarde-box{
          width: 100%;
          padding:0px 20px 20px 0px;
          display:flex;
          justify-content: end;
          @media screen and (max-width: 786px){
            justify-content:center;
            .MuiButton-root{
              max-width:260px !important;
            }
          }
        }
      }
    }    
  `,
);

export default StyledSubscription;
